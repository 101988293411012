import React from "react";
import { useOperatorStyles } from "../../style";
import {
  Button,
  Paper,
  FormControl,
  TextareaAutosize,
} from "@material-ui/core";
import { useState } from "react";
import { sendMessage } from "../../../../../../api/requests";

const SendMessage = ({ activeOperator, activeProject, activeOperatorData }) => {
  const classes = useOperatorStyles();
  const [message, setMessage] = useState("");
  const [numbers, setNumbers] = useState("");
  const [messageData, setMessageData] = useState({
    message: null,
    error: null,
  });

  const onNumbersChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      return setNumbers(value);
    }
    const regex = /^[0-9.,\b]+$/;
    if (regex.test(value)) {
      setNumbers(value);
    }
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (message.trim() && numbers.trim()) {
      const url = numbers.includes(",")
        ? activeOperatorData.bulk_url
        : activeOperatorData.single_url;
      await sendMessage(numbers, message, activeProject, url, setMessageData);
    }
  };

  return (
    <div className={classes.count}>
      <Paper className={classes.paper} elevation={3}>
        <div className={classes.singleHeader}>
          <div className={classes.countText}>SEND MESSAGE</div>
        </div>
        <div className={classes.formWrapper}>
          <form onSubmit={onFormSubmit}>
            <FormControl>
              <TextareaAutosize
                className={classes.textField}
                minRows={3}
                maxRows={3}
                disabled={!activeOperator}
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />

              <TextareaAutosize
                minRows={3}
                maxRows={3}
                disabled={!activeOperator}
                className={classes.textField}
                placeholder="* Bulk -Please enter numbers in this format:
                              255000000000,255111111111...
                              WARNING - Set maximum 1000 numbers"
                value={numbers}
                onChange={onNumbersChange}
              />

              <Button
                onClick={onFormSubmit}
                disabled={!activeOperator}
                className={classes.sendButton}
                variant="contained"
                color="primary"
              >
                Send
              </Button>
            </FormControl>
          </form>
          {messageData.message && (
            <div className={classes.messageWrapper}>
              <div
                className={classes.messageIcon}
                style={{ backgroundColor: messageData.error ? "red" : "green" }}
              />
              <div className={classes.errorText}>{messageData.message}</div>
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default SendMessage;
