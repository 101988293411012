import React from "react";
import Paper from "@material-ui/core/Paper";
import { TextField, Button } from "@material-ui/core";
import { useState } from "react";
import { useLoginStyles } from "./style";

const Login = ({ onAuthClck, verifyError, setVerifyError }) => {
  const classes = useLoginStyles();
  const [input, setInput] = useState("");

  const onInputChange = (e) => {
    setInput(e.target.value);
    setVerifyError("");
  };

  const onButtonClick = () => {
    input.trim() && onAuthClck(input);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={3}>
        <div className={classes.paperWrapper}>
          <TextField
            className={classes.width80}
            label={verifyError || "Secret Key"}
            variant="outlined"
            type="password"
            error={!!verifyError}
            value={input}
            onChange={onInputChange}
            onKeyPress={(e) => e.key === "Enter" && onButtonClick()}
          />
          <Button
            onClick={onButtonClick}
            className={classes.width80}
            style={{ marginTop: "30px" }}
            variant="contained"
            color="primary"
          >
            Verify
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default Login;
