const { io } = require('socket.io-client');
const { ACTIONS_ENUM } = require('../constants/actions');
// const API_V1 = process.env.REACT_APP_API_V1;

const socket = io();
export function callGetMessageCount(activeProject, activeOperator, status) {
  socket.emit(ACTIONS_ENUM.ON_MESSAGE_COUNT, {
    via: activeOperator,
    project: activeProject,
    status: status,
  });
}
export function GetMessageCount(status, setCount) {
  socket.on(`${status}_messages_count`, response => {
    setCount(response?.messageCount);
  });
}
