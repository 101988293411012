import React from 'react';
import { Button } from '@material-ui/core';
import { useOperatorStyles } from './style';

const OperatorsList = ({ activeOperator, setActiveOperator, operatorsData }) => {
  const classes = useOperatorStyles();

  return (
    <div>
      <div className={classes.operatorsTextContainer}>
        <div className={classes.line} />
        <div className={classes.operatorsText}>OPERATORS</div>
        <div className={classes.line} />
      </div>
      <div className={classes.operatorsContainer}>
        <div className={classes.operatorsList}>
          {Object.keys(operatorsData).map(operator => (
            <Button
              key={operator}
              variant="contained"
              color={activeOperator === operator ? 'secondary' : 'primary'}
              className={classes.operatorItem}
              onClick={() => setActiveOperator(operator)}>
              {operator}
            </Button>
          ))}
        </div>
      </div>
      {!activeOperator && (
        <div style={{ marginTop: 8 }} className={classes.line} />
      )}
    </div>
  );
};

export default OperatorsList;
