import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line no-unused-vars
export const useLoginStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '30vw',
    height: '22vw',
    minHeight: '250px',
    minWidth: '350px',
  },
  paperWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    boxSizing: 'border-box',
  },
  width80: {
    width: '80%',
  },
}));
