import { useOperatorStyles } from '../../style';
import {
  callGetMessageCount,
  GetMessageCount,
} from '../../../../../../api/socket';
import { useEffect, useState } from 'react';

const delay = 1000;

export const MessageCount = ({ status, activeProject, activeOperator }) => {
  const classes = useOperatorStyles();
  const [count, setCountData] = useState();

  useEffect(() => {
    if (activeProject && activeOperator) {
      const time = setInterval(() => {
        callGetMessageCount(activeProject, activeOperator, status);
      }, delay * 1000);
      return () => clearInterval(time);
    }
  }, [activeProject, activeOperator]);

  useEffect(() => {
    const time = setTimeout(async () => {
      await GetMessageCount(status, setCountData);
    }, 20);
    return () => clearInterval(time);
  });

  return (
    <div className={classes.countWrapper}>
      <div className={classes.dateHeader}>{status}</div>
      <div className={classes.currentCount}>{count || '-'}</div>
    </div>
  );
};
