import { makeStyles } from '@material-ui/core';

export const useProjectStyle = makeStyles(theme => ({
  projectsTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#3f51b5',
  },
  line: {
    width: '100%',
    backgroundColor: '#3f51b5',
    height: '1px',
  },
  projectsText: {
    fontSize: 16,
    padding: '0 10px',
    fontWeight: 'bold',
    letterSpacing: '2px',
  },
  projectsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  projectsList: {
    display: 'flex',
    padding: '10px',
    overflow: 'auto',
  },
  projectsItem: {
    minWidth: '130px',
    height: '90px',
    margin: '0 20px',
  },
}));
