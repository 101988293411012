import { makeStyles } from '@material-ui/core';

export const useOperatorStyles = makeStyles(theme => ({
  operatorsTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#3f51b5',
  },
  line: {
    width: '100%',
    backgroundColor: '#3f51b5',
    height: '1px',
  },
  operatorsText: {
    fontSize: 16,
    padding: '0 10px',
    fontWeight: 'bold',
    letterSpacing: '2px',
  },
  operatorsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  operatorsList: {
    display: 'flex',
    padding: '10px',
    overflow: 'auto',
  },
  operatorItem: {
    minWidth: '130px',
    height: '90px',
    margin: '0 20px',
  },
}));
