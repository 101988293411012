import React, { useState, useMemo } from 'react';
import { useMainStyles } from './style';
import OperatorsList from './MainComponents/OperatorsList/OperatorsList';
import Operator from './MainComponents/Operator/Operator';
import Projects from './MainComponents/Projects/Projects';

const Main = ({ operatorsData }) => {
  const [activeOperator, setActiveOperator] = useState('');
  const [activeProject, setActiveProject] = useState('');

  const activeOperatorData = useMemo(
    () => operatorsData[activeOperator],
    [activeOperator, operatorsData],
  );

  const classes = useMainStyles();

  return (
    <div className={classes.root}>
      <OperatorsList
        activeOperator={activeOperator}
        setActiveOperator={setActiveOperator}
        operatorsData={operatorsData}
      />
      {activeOperator && (
        <Projects
          activeProject={activeProject}
          setActiveProject={setActiveProject}
          activeOperator={activeOperator}
          operatorsData={operatorsData}
        />
      )}
      <Operator
        activeProject={activeProject}
        activeOperatorData={activeOperatorData}
        activeOperator={activeOperator}
      />
    </div>
  );
};

export default Main;
