import React, { useMemo, useEffect } from "react";
import { Button } from "@material-ui/core";
import { useProjectStyle } from "./style";

const Projects = ({
  activeProject,
  setActiveProject,
  operatorsData,
  activeOperator,
}) => {
  const projects = useMemo(
    () => operatorsData[activeOperator].projects || [],
    [activeOperator, operatorsData]
  );

  useEffect(() => {
    if (activeOperator && projects.length) {
      setActiveProject(projects[0]);
    }
  }, [activeOperator]);

  const classes = useProjectStyle();

  return (
    <div>
      <div className={classes.projectsTextContainer}>
        <div className={classes.line} />
        <div className={classes.projectsText}>PROJECTS</div>
        <div className={classes.line} />
      </div>
      <div className={classes.projectsTextContainer}>
        <div className={classes.projectsList}>
          {projects.map((project) => (
            <Button
              key={project}
              variant="contained"
              color={activeProject === project ? "secondary" : "primary"}
              className={classes.projectsItem}
              onClick={() => setActiveProject(project)}
            >
              {project}
            </Button>
          ))}
        </div>
      </div>
      <div style={{ marginTop: 8 }} className={classes.line} />
    </div>
  );
};

export default Projects;
