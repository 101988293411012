import React from 'react';
import { Paper } from '@material-ui/core';
import { useOperatorStyles } from '../../style';
import { MessageCount } from './MessageCount';
const BulkMessageCount = ({ activeOperator, activeProject }) => {
  const classes = useOperatorStyles();

  return (
    <div className={classes.count}>
      <Paper className={classes.paper} elevation={3}>
        <div>
          <div className={classes.singleHeader}>
            <div className={classes.countText}>BULK MESSAGES COUNT</div>
          </div>
          <MessageCount
            status={'PENDING'}
            activeProject={activeProject}
            activeOperator={activeOperator}
          />
          <MessageCount
            status={'FAILED'}
            activeProject={activeProject}
            activeOperator={activeOperator}
          />
          <MessageCount
            status={'SENT'}
            activeProject={activeProject}
            activeOperator={activeOperator}
          />
        </div>
      </Paper>
    </div>
  );
};

export default BulkMessageCount;
