import { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header/Header';
import Login from './components/Login/Login';
import Main from './components/Main/Main';
import { getOperatorsList, secretPassword } from './api/requests';

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [verifyError, setVerifyError] = useState('');
  const [operatorsData, setOperatorsData] = useState(null);

  const onAuthClick = async text => {
    const { message, error } = await secretPassword(text);

    if (!operatorsData) {
      setIsAuth(false);
      setVerifyError('You don`t have connection');
    }

    if (error) {
      setIsAuth(false);
      setVerifyError(message);
    } else {
      setIsAuth(true);
      setVerifyError(error);
    }
  };

  useEffect(() => {
    getOperatorsList(setOperatorsData);
  }, []);

  return (
    <div className="App">
      <Header />
      {!!isAuth && operatorsData ? (
        <Main operatorsData={operatorsData} />
      ) : (
        <Login
          onAuthClck={onAuthClick}
          verifyError={verifyError}
          setVerifyError={setVerifyError}
        />
      )}
    </div>
  );
}

export default App;
