import React from 'react';
import { useOperatorStyles } from './style';
import 'react-datepicker/dist/react-datepicker.css';
import SuccessMessageCount from './Components/SuccessMessageCount/SuccessMessageCount';
import SendMessage from './Components/SendMessage/SendMessage';
import BulkMessageCount from './Components/BulkMessageCount/BulkMessageCount';

const Operator = ({ activeOperator, activeProject, activeOperatorData }) => {
  const classes = useOperatorStyles();

  return (
    <div className={classes.root}>
      <div className={classes.operatorText}>
        {activeOperator.toUpperCase() || (
          <span className={classes.operatorNotSelected}>
            OPERATOR NOT SELECTED{' '}
          </span>
        )}
      </div>
      <div className={classes.operatorBody}>
        <SuccessMessageCount activeOperator={activeOperator} activeProject={activeProject} />
        <BulkMessageCount
          activeOperator={activeOperator}
          activeProject={activeProject}
        />
        <SendMessage
          activeOperatorData={activeOperatorData}
          activeOperator={activeOperator}
          activeProject={activeProject}
        />
      </div>
    </div>
  );
};

export default Operator;
