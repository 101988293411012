import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Paper } from '@material-ui/core';
import { useOperatorStyles } from '../../style';
import { getCounts } from '../../../../../../api/requests';

const initialCount = { singleCount: '-', bulkCount: '-' };

const SuccessMessageCount = ({ activeOperator, activeProject }) => {
  const classes = useOperatorStyles();

  const [fromDate, setFromDate] = useState(() => new Date());
  const [toDate, setToDate] = useState(() => new Date());
  const [countData, setCountData] = useState(initialCount);

  useEffect(() => {
    if (activeProject && activeOperator && fromDate && toDate) {
      setCountData(initialCount);
      getCounts(activeProject, activeOperator, fromDate, toDate, setCountData);
    }
  }, [activeProject, activeOperator, fromDate, toDate]);

  return (
    <div className={classes.count}>
      <Paper className={classes.paper} elevation={3}>
        <div className={classes.singleHeader}>
          <div className={classes.countText}>SUCCESS MESSAGES COUNT</div>
        </div>
        <div>
          <div className={classes.dateWrapper}>
            <div className={classes.datePickerWrapper}>
              <div className={classes.dateHeader}>FROM</div>
              <div>
                <DatePicker
                  selected={fromDate}
                  onChange={value => setFromDate(value)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  showTimeInput
                  timeInputLabel="Time:"
                  customInput={
                    <input
                      className={`${classes.customInput} ${
                        !activeOperator && classes.inactiveColor
                      }`}
                    />
                  }
                  dateFormat="MM/dd/yyyy h:mm aa"
                  disabled={!activeOperator}
                  maxDate={Date.now()}
                />
              </div>
            </div>
            <div style={{ margin: '0 6px 6px' }}>-</div>
            <div className={classes.datePickerWrapper}>
              <div className={classes.dateHeader}>TO</div>
              <div>
                <DatePicker
                  selected={toDate}
                  onChange={value => setToDate(value)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  showTimeInput
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  customInput={
                    <input
                      className={`${classes.customInput} ${
                        !activeOperator && classes.inactiveColor
                      }`}
                    />
                  }
                  disabled={!activeOperator}
                  maxDate={Date.now()}
                />
              </div>
            </div>
          </div>
          <div className={classes.dateWrapper}>
            <div className={classes.countWrapper}>
              <div className={classes.dateHeader}>SINGLE</div>
              <div className={classes.currentCount}>
                {countData.singleCount}
              </div>
            </div>
            <div className={classes.countWrapper}>
              <div className={classes.dateHeader}>BULK</div>
              <div className={classes.currentCount}>{countData.bulkCount}</div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default SuccessMessageCount;
