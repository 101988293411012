import axios from 'axios';
import moment from 'moment';

// const API = process.env.REACT_APP_API;
const API_V1 = process.env.REACT_APP_API_V1;

export const getCounts = async (
  db_name,
  activeOperator,
  fromDate,
  toDate,
  setCountData,
) => {
  try {
    const data = {
      project: db_name,
      operator: activeOperator,
      toDate: moment(toDate).format('YYYY-MM-DD HH:mm:ss'),
      fromDate: moment(fromDate).format('YYYY-MM-DD HH:mm:ss'),
    };
    const res = await axios.get(`${API_V1}/get-count`, { params: data });
    res?.data?.data && setCountData(res?.data?.data);
  } catch (error) {
    console.error('getCounts Error', error);
  }
};

export const getOperatorsList = async setOperatorsData => {
  try {
    const res = await axios.get(`${API_V1}/get-operator-list`);
    res.data && setOperatorsData(res.data);
  } catch (error) {
    console.error('getOperatorList Error', error);
  }
};

export async function sendMessage(to, message, project, operatorUrl, setData) {
  try {
    const data = {
      to,
      message,
      project,
      from: 'MU',
    };
    setData({
      error: false,
      message: `Message in PENDING status, since-${new Date()}.Pls wait until it send, brat`,
    });
    const response = await axios.post(`${API_V1}${operatorUrl}`, data);
    response && setData(response.data);
  } catch (e) {
    setData({
      error: true,
      message:
        e?.response?.data?.message || `Server error, pls contact to support`,
    });
    console.error(`singleRequest Error: `, e);
  }
}

export async function secretPassword(verifyKey) {
  try {
    const { data, error } = await axios.get(`${API_V1}/auth`, {
      headers: {
        password: verifyKey,
      },
    });

    return !error && data;
  } catch (e) {
    console.error('secretPassword Error:', e);
    return {
      error: true,
      message:
          e?.response?.data?.message || `Server error, pls contact to support`,
    };
  }
}
