import { makeStyles } from '@material-ui/core';

export const useOperatorStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 40,
  },
  operatorText: {
    fontSize: 18,
    padding: '0 10px',
    fontWeight: 'bold',
    letterSpacing: '2px',
    color: '#F50057',
  },
  operatorNotSelected: {
    color: 'gray',
  },
  operatorBody: {
    width: '90%',
    marginTop: 30,
    display: 'flex',
    justifyContent: 'space-between',
    color: '#3f51b5',
    '@media only screen and (max-width: 1023px)': {
      flexDirection: 'column',
    },
  },
  count: {
    width: '45%',
    '@media only screen and (max-width: 1023px)': {
      marginTop: 20,
      width: '100%',
    },
  },
  singleHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  countText: {
    whiteSpace: 'nowrap',
    margin: '0 15px',
    letterSpacing: '2px',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#3f51b5',
  },
  line: {
    width: '100%',
    backgroundColor: 'gray',
    height: '1px',
  },
  customInput: {
    backgroundColor: '#3f51b5',
    border: 'none',
    borderRadius: '5px',
    color: 'white',
    padding: 5,
    width: 150,
  },
  dateWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: 38,
  },
  datePickerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  countWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '30px 70px 0',
  },
  dateHeader: {
    fontSize: 13,
    color: 'gray',
    marginBottom: 3,
  },
  inactiveColor: {
    backgroundColor: 'gray',
  },
  currentCount: {
    fontSize: 30,
  },
  paper: {
    padding: '40px 20px',
    width: 'fit-content',
    margin: '0 auto',
  },
  textField: {
    width: 300,
    marginTop: 15,
  },
  formWrapper: {
    margin: '0 30px',
  },
  sendButton: {
    marginTop: 20,
  },
  textarea: {
    marginTop: 20,
    padding: 5,
  },
  messageWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
  },
  messageIcon: {
    width: 15,
    height: 15,
    borderRadius: 3,
    marginRight: 15,
    backgroundColor: 'gray',
  },
}));
